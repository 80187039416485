
import { defineComponent } from 'vue'
import MotifClass from '@/types/class'
import utils from '@/utils/utils'
import AdminNav from '@/components/AdminNav.vue'

export default defineComponent({
  components: { AdminNav },
  data () {
    return {
      classes: [] as MotifClass[]
    }
  },
  async mounted () {
    const response = await this.$api.listClasses()
    this.classes = response.classes || []
  },
  methods: {
    dateRange (motifClass: MotifClass) {
      return utils.getClassDateRange(motifClass)
    }
  }
})
