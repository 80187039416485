<template>
  <div class="container-fluid mt-3">
    <div class="row">
      <admin-nav />
      <div class="col-lg-10 col-sm-12">
        <div class="container">
          <h4 class="mb-3">Manage classes</h4>
          <div class="mb-3">
            <router-link class="btn btn-success" :to="{ name: 'admin_class', params: { id: 'new' } }">New Class</router-link>
          </div>
          <div class="accordion" id="accordionClasses">
            <div v-for="(motifClass, index) in classes" :key="index" class="accordion-item">
              <h2 class="accordion-header" :id="'heading' + index">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse' + index" aria-expanded="false" :aria-controls="'collapseOne' + index">
                  {{ motifClass.courseName }} at {{ motifClass.location }} ({{ dateRange(motifClass) }}) - ({{ motifClass.enrollment }}/{{ motifClass.capacity }} Students)
                </button>
              </h2>
              <div :id="'collapse' + index" class="accordion-collapse collapse" :aria-labelledby="'heading' + index" data-bs-parent="#accordionClasses">
                <div class="accordion-body">
                  <router-link class="btn btn-primary me-3 mb-3" :to="{ name: 'admin_class', params: { id: motifClass.id } }">View/edit details</router-link>
                  <router-link class="btn btn-success mb-3" :to="{ name: 'admin_class_enrollments', params: { id: motifClass.id } }">View Enrollments</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MotifClass from '@/types/class'
import utils from '@/utils/utils'
import AdminNav from '@/components/AdminNav.vue'

export default defineComponent({
  components: { AdminNav },
  data () {
    return {
      classes: [] as MotifClass[]
    }
  },
  async mounted () {
    const response = await this.$api.listClasses()
    this.classes = response.classes || []
  },
  methods: {
    dateRange (motifClass: MotifClass) {
      return utils.getClassDateRange(motifClass)
    }
  }
})
</script>
